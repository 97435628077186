import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { Input } from 'src/components';

import { normalizeAllAgeRates, coverageAllAges, COVERAGE_ALL_AGES_NAMES } from './coverageAllAges';
import {
  coverageFiveYearAges,
  COVERAGE_FIVE_YEAR_NAMES,
  normalizeCoverageFiveYearAgeRates,
} from './coverageFiveYearAges';
import {
  normalizeCoverageTenYearAgeRates,
  coverageTenYearAges,
  COVERAGE_TEN_YEAR_NAMES,
} from './coverageTenYearAges';

import { threeDigitsRegex } from 'src/constants/regularExpression';

import classes from './coverageAgeBandedRates.module.scss';

export const CoverageAgeBandedRates = (props) => {
  const {
    tierType,
    ageBandedRates,
    selectedAllAges,
    setSelectedAllAges,
    selectedFiveYearAges,
    setSelectedFiveYearAges,
    selectedTenYearAges,
    setSelectedTenYearAges,
    onSetAllAgeBandedRates,
    onSetFiveYearAgeBandedRates,
    onSetTenYearAgeBandedRates,
    isEditMode = true,
    isIssuer = false,
  } = props;

  const [allAgeBandedRates, setAllAgeBandedRates] = useState({});
  const [fiveYearAgeRates, setFiveYearAgeRates] = useState({});
  const [tenYearAgeRates, setTenYearAgeRates] = useState({});

  const [allAgeBandedRatesPayload, setAllAgeBandedRatesPayload] = useState({});
  const [fiveYearAgeRatesPayload, setFiveYearAgeRatesPayload] = useState({});
  const [tenYearAgeRatesPayload, setTenYearAgeRatesPayload] = useState({});

  const [selectedTarget, setSelectedTarget] = useState('');

  useEffect(() => {
    setAllAgeBandedRates(
      isIssuer
        ? selectedAllAges || coverageAllAges(ageBandedRates)
        : coverageAllAges(ageBandedRates),
    );
    setFiveYearAgeRates(
      isIssuer
        ? selectedFiveYearAges || coverageFiveYearAges(ageBandedRates)
        : coverageFiveYearAges(ageBandedRates),
    );
    setTenYearAgeRates(
      isIssuer
        ? selectedTenYearAges || coverageTenYearAges(ageBandedRates)
        : coverageTenYearAges(ageBandedRates),
    );
  }, [ageBandedRates, selectedTenYearAges, isIssuer, selectedAllAges, selectedFiveYearAges]);

  useEffect(() => {
    if (tierType === 'all_ages') {
      const payload = normalizeAllAgeRates(allAgeBandedRates);

      setAllAgeBandedRatesPayload(payload);
    }
  }, [tierType, allAgeBandedRates]);

  useEffect(() => {
    if (tierType === 'banded_5') {
      const payload = normalizeCoverageFiveYearAgeRates(fiveYearAgeRates);

      setFiveYearAgeRatesPayload(payload);
    }
  }, [tierType, fiveYearAgeRates]);

  useEffect(() => {
    if (tierType === 'banded_10') {
      const payload = normalizeCoverageTenYearAgeRates(tenYearAgeRates);

      setTenYearAgeRatesPayload(payload);
    }
  }, [tierType, tenYearAgeRates]);

  useEffect(() => {
    if (onSetAllAgeBandedRates) {
      onSetAllAgeBandedRates(allAgeBandedRatesPayload);
    }
  }, [onSetAllAgeBandedRates, allAgeBandedRatesPayload]);

  useEffect(() => {
    if (onSetFiveYearAgeBandedRates) {
      onSetFiveYearAgeBandedRates(fiveYearAgeRatesPayload);
    }
  }, [onSetFiveYearAgeBandedRates, fiveYearAgeRatesPayload]);

  useEffect(() => {
    if (onSetTenYearAgeBandedRates) {
      onSetTenYearAgeBandedRates(tenYearAgeRatesPayload);
    }
  }, [onSetTenYearAgeBandedRates, tenYearAgeRatesPayload]);

  const onFocusInput = useCallback((event, target, value, setValue) => {
    setSelectedTarget(target);

    if (Number(event.target.value) === 0) {
      const payload = {
        ...value,
        [target]: '',
      };

      return setValue(payload);
    }
  }, []);

  const onBlurInput = useCallback((event, target, value, setValue) => {
    setSelectedTarget('');

    if (!event.target.value) {
      const payload = {
        ...value,
        [target]: 0,
      };

      return setValue(payload);
    }
  }, []);

  const onChangeAllBandedAgeInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...allAgeBandedRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setSelectedAllAges(payload);
        }
        return setAllAgeBandedRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...allAgeBandedRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setSelectedAllAges(payload);
        }
        return setAllAgeBandedRates(payload);
      }

      payload = {
        ...allAgeBandedRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setSelectedAllAges(payload);
      }
      setAllAgeBandedRates(payload);
    },
    [allAgeBandedRates, isIssuer, selectedTarget, setSelectedAllAges],
  );

  const onChangeFiveYearAgeInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...fiveYearAgeRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setSelectedFiveYearAges(payload);
        }
        return setFiveYearAgeRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...fiveYearAgeRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setSelectedFiveYearAges(payload);
        }
        return setFiveYearAgeRates(payload);
      }

      payload = {
        ...fiveYearAgeRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setSelectedFiveYearAges(payload);
      }
      setFiveYearAgeRates(payload);
    },
    [fiveYearAgeRates, isIssuer, selectedTarget, setSelectedFiveYearAges],
  );

  const onChangeTenYearAgeInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...tenYearAgeRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setSelectedTenYearAges(payload);
        }
        return setTenYearAgeRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...tenYearAgeRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setSelectedTenYearAges(payload);
        }
        return setTenYearAgeRates(payload);
      }

      payload = {
        ...tenYearAgeRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setSelectedTenYearAges(payload);
      }
      setTenYearAgeRates(payload);
    },
    [tenYearAgeRates, selectedTarget, isIssuer, setSelectedTenYearAges],
  );

  const normalizeAllAgeLabel = useCallback((item) => {
    return COVERAGE_ALL_AGES_NAMES[item] === 'Age 80' ? 'Age 80+' : COVERAGE_ALL_AGES_NAMES[item];
  }, []);

  const renderAllAgeBandedRates = useCallback(
    () =>
      Object?.keys(allAgeBandedRates)?.map((item) => (
        <td key={item} className={isIssuer ? classes.IssuerCoverageAgeBandedWrapper : ''}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={normalizeAllAgeLabel(item)}
                  tierType="number"
                  value={allAgeBandedRates[item]}
                  onFocus={(event) =>
                    onFocusInput(event, item, allAgeBandedRates, setAllAgeBandedRates)
                  }
                  onBlur={(event) =>
                    onBlurInput(event, item, allAgeBandedRates, setAllAgeBandedRates)
                  }
                  onChange={onChangeAllBandedAgeInput}
                  type="number"
                />
                {isIssuer && `(${normalizeAllAgeLabel(item)})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${allAgeBandedRates[item] || '0'} (${normalizeAllAgeLabel(item)})`}
          </>
        </td>
      )),
    [
      allAgeBandedRates,
      isEditMode,
      isIssuer,
      normalizeAllAgeLabel,
      onBlurInput,
      onChangeAllBandedAgeInput,
      onFocusInput,
    ],
  );

  const normalizeFiveYearLabel = useCallback((item) => {
    return COVERAGE_FIVE_YEAR_NAMES[item] === 'Age 80' ? 'Age 80+' : COVERAGE_FIVE_YEAR_NAMES[item];
  }, []);

  const renderFiveAgeBandedRates = useCallback(
    () =>
      Object?.keys(fiveYearAgeRates)?.map((item) => (
        <td key={item} className={isIssuer ? classes.IssuerCoverageAgeBandedWrapper : ''}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={normalizeFiveYearLabel(item)}
                  tierType="number"
                  value={fiveYearAgeRates[item]}
                  onFocus={(event) =>
                    onFocusInput(event, item, fiveYearAgeRates, setFiveYearAgeRates)
                  }
                  onBlur={(event) =>
                    onBlurInput(event, item, fiveYearAgeRates, setFiveYearAgeRates)
                  }
                  onChange={onChangeFiveYearAgeInput}
                  type="number"
                />
                {isIssuer && `(${normalizeFiveYearLabel(item)})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${fiveYearAgeRates[item] || '0'} (${normalizeFiveYearLabel(item)})`}
          </>
        </td>
      )),
    [
      fiveYearAgeRates,
      isIssuer,
      isEditMode,
      normalizeFiveYearLabel,
      onFocusInput,
      onBlurInput,
      onChangeFiveYearAgeInput,
    ],
  );

  const normalizeTenYearLabel = useCallback((item) => {
    return COVERAGE_TEN_YEAR_NAMES[item] === 'Age 80' ? 'Age 80+' : COVERAGE_TEN_YEAR_NAMES[item];
  }, []);

  const renderTenAgeBandedRates = useCallback(
    () =>
      Object?.keys(tenYearAgeRates)?.map((item) => (
        <td key={item} className={isIssuer ? classes.IssuerCoverageAgeBandedWrapper : ''}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={normalizeTenYearLabel(item)}
                  tierType="number"
                  value={tenYearAgeRates[item]}
                  onFocus={(event) =>
                    onFocusInput(event, item, tenYearAgeRates, setTenYearAgeRates)
                  }
                  onBlur={(event) => onBlurInput(event, item, tenYearAgeRates, setTenYearAgeRates)}
                  onChange={onChangeTenYearAgeInput}
                  type="number"
                />
                {isIssuer && `(${normalizeTenYearLabel(item)})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${tenYearAgeRates[item] || '0'} (${normalizeTenYearLabel(item)})`}
          </>
        </td>
      )),
    [
      tenYearAgeRates,
      isIssuer,
      isEditMode,
      normalizeTenYearLabel,
      onFocusInput,
      onBlurInput,
      onChangeTenYearAgeInput,
    ],
  );

  const ageBandedRatesStyles = classnames({
    [classes.CoverageAgeBandedRates]: tierType !== 'all_ages',
    [classes.AllCoverageAgeBandedRates]: tierType === 'all_ages',
  });

  return (
    <div className={!isIssuer ? ageBandedRatesStyles : ''}>
      {tierType === 'all_ages' && renderAllAgeBandedRates()}
      {tierType === 'banded_5' && renderFiveAgeBandedRates()}
      {tierType === 'banded_10' && renderTenAgeBandedRates()}
    </div>
  );
};
